.Content-Column .Calendar-Box {
    text-align: left;
    padding: 1rem;
}

.Content-Column .Calendar-Box a, .Content-Column .Calendar-Box a:visited {
    color: white;
    text-decoration: none;
}

.Content-Column .Calendar-Box .Label img {
    width: 16px;
}

.SideBar .Calendar-Box .Label img {
    width: 16px;
}

.Content-Column .Tournament-Filter-Tabs {
    list-style-type: none;
    margin: 0;
    padding-inline-start: 0;
    font-weight: bold;
}

.Content-Column .Tournament-Filter-Links-Wrapper {
    background-color: #383838;
    color: white;
    text-align: left;
    padding: 10px 15px;
    margin: 10px 0;
    vertical-align: middle;
}

.Content-Column .Tournament-Filter-Link {
    cursor: pointer;
    user-select: none;
    background-color: #191919;
    width: 40px;
    height: 40px;
    text-align: center;
    vertical-align: middle;
    display: inline-block;
    margin: 5px;
    padding: 5px;
    line-height: 40px;
}

.Content-Column .Tournament-Filter-Tabs li img {
    vertical-align: text-bottom;
}

.Content-Column .Tournament-Filter-Tabs li img.Tournament-Tab-Icon-finnish {
    vertical-align: baseline;
}

.Content-Column .Tournament-Filter-Tabs li {
    background-color: #383838;
    color: white;
    text-align: center;
    padding: 10px 15px;
    display: inline-block;
    width: auto;
    margin: 10px 2.5%;
    font-size: 15px;
    cursor: pointer;
    user-select: none;
}

.Content-Column .Tournament-Filter-Tabs li:first-child {
    margin-left: 0;
}

.Content-Column .Tournament-Filter-Tabs li:last-child {
    margin-right: 0;
}

.Content-Column .Calendar-Box .Calendar-Match .Match-AirTime {
    right: unset;
    left: 10px;
    font-size: 18px;
}

.Content-Column .Calendar-Box .Tournament-Info {
    position: absolute;
    left: calc(50% + 120px);
    top: calc(50% - 20px);
    color: white;
    font-size: 40%;
}

.Content-Column .Calendar-Box .Tournament-Title {
    color: #FF3300;
}

.Content-Column .Tournament-Filter-Tab-Active {
    border-bottom: 2px solid #FF3300;
}

.Content-Column .Tournament-Filter-Link-Active {
    outline: 2px solid #FF3300;
}

.Content-Column .Calendar-Box .Tournament-Logo {
    width: unset;
    height: 36px;
    margin: 0 5px;
    aspect-ratio: 16 / 9;
    display: inline-block;
    object-fit: contain;
    overflow: hidden;
}

.Calendar-Box .Label {
    zoom: 1;
    background: none;
    float: left;
}

.Calendar-Box .Divider {
    color: #ccc;
}

.Calendar-Attribution::before {
    border-bottom: 1px solid #565656;
    content: "";
    display: block;
    position: absolute;
    left: 0;
    bottom: 50%;
    height: 1px;
    width: 33%;
}

.Calendar-Box .Calendar-Attribution .Liquipedia-Logo {
    height: 18px;
    vertical-align: bottom;
}

.Content-Column .Calendar-Box .Tournament-Match-Icons {
    position: absolute;
    bottom: calc(50% - 1px);
    overflow: visible;
    right: 5px;
    height: 1px;
    text-align: right;
}

.Content-Column .Calendar-Box .Tournament-Match-Icons > img {
    padding: 0;
    margin: 0;
    position: absolute;
    bottom: 0;
    right: 0;
}

.Calendar-Box .Calendar-Attribution {
    font-size: 85%;
    margin-top: 1rem;
    text-align: right;
    color: #ccc;
    position: relative;
}

.Content-Column .Calendar-Box .Calendar-Attribution {
    font-size: 14px;
}

.Calendar-Box .Calendar-Match-Date-Title {
    font-size: 21px;
    margin: 0.5rem;
    text-align: left;
    color: #ff3300;
    font-weight: bold;
    text-shadow: 1px 1px 1px black;
}

.Calendar-Match + .Calendar-Box .Calendar-Match-Date-Title {
    margin-top: 20px;
}

.Calendar-Box .Calendar-Match-Date-Title::first-letter {
    text-transform: uppercase;
}

.Calendar-Box > div {
    background: none;
    margin-top: 0;
    padding: 0;
    clear: right;
}

.Calendar-Box .Calendar-Match:nth-child(odd) {
    background-color: #383838;
}

.Content-Column .Calendar-Box .Calendar-Match {
    padding-left: 70px;
}

.Calendar-Box .Calendar-Match {
    padding: 5px 0;
    position: relative;
    clear: both;
}

.Calendar-Box .Calendar-Match::after {
    content: "";
    display: block;
    clear: both;
}

.Calendar-Box .Calendar-Match .Match-Score {
    float: right;
    padding: 0 20px;
}

.Calendar-Box .Calendar-Match .Match-AirTime {
    position: absolute;
    right: 20px;
    top: calc(50% - 10px);
    font-size: 16px;
    font-weight: bold;
    color: white;
}

.Calendar-Box .Calendar-Match .Match-AirTimeAndDate {
    position: absolute;
    right: 20px;
    top: calc(50% - 20px);
    font-size: 16px;
    font-weight: bold;
    color: white;
    max-width: 60px;
}

.Calendar-Box .Calendar-Match-Title {
    float: left;
    line-height: 125%;
    color: white;
}

.Content-Column .Calendar-Box .Calendar-Match-Title {
    font-size: 14px;
    line-height: 22px;
}

.Content-Column .Calendar-Box .Calendar-Match-Odds-Box {
    background: #01478f;
    border: 2px solid #031c6f;
    /** @todo: IMPLEMENT */
}

.Calendar-Box .Finnish-Flag {
    background-image: url(../images/finnish_flag.png);
    background-position: 0 0;
    background-repeat: no-repeat;
    background-size: contain;
    border-radius: 30px;
    height: 21px;
    margin: 0 0 0 5px;
    width: 14px;
    background-color: transparent !important;
    padding: 0;
    display: inline-block;
    vertical-align: middle;
}

.Calendar-Box .Match-Team-Logo, .Tournament-Logo {
    height: 16px;
    margin: 0 0 0 5px;
    width: 16px;
    padding: 0;
    display: inline-block;
    vertical-align: middle;
}

.Content-Column .Tournament-Filter-Link .Tournament-Logo {
    max-height: calc(100% - 10px);
    width: max-content;
    height: max-content;
    max-width: calc(100% - 10px);
    vertical-align: middle;
}

.Calendar-Box .TBD-Team {
    background-image: url(../images/tbd_team.jpg);
    background-position: 0 0;
    background-repeat: no-repeat;
    background-size: contain;
    border-radius: 30px;
    height: 16px;
    margin: 0 0 0 5px;
    width: 16px;
    padding: 0;
    display: inline-block;
    vertical-align: middle;
}

.Tournament-Tab-Icon-tournaments {
    filter: invert(1);
}

.Tournament-Tab-Icon-top {
    filter: brightness(0) saturate(100%) invert(93%) sepia(88%) saturate(4457%) hue-rotate(2deg) brightness(101%);
}

@media (max-width: 767px) {
    .Content-Column .Calendar-Box .Tournament-Info {
        left: unset;
        right: 20px;
    }
    .Content-Column .Calendar-Box .Tournament-Info > a {
        font-size: 0;
    }
}
