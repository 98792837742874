.SideBar {
    flex: 0 0 320px; /* Fixed width of 320px */
    max-width: calc(320px - 2rem);
    min-width: calc(320px - 2rem);
    background-color: #1a1a1a;
    color: #fff;
    font-size: 0.5em;
    padding: 0 1rem;
    text-align: left;
}

.SideBar a {
    color: #fff;
    text-decoration: none;
}

.SideBar div {
    background: #191919;
    padding: 10px;
    margin-top: 2.5rem;
}

.SideBar div:first-child {
    margin-top: 0;
}

.SideBar ul {
    list-style-type: none;
    padding-left: 0;
}

.SideBar li {
    border-bottom: 1px solid #666;
    padding-bottom: 15px;
    margin-bottom: 18px;
    position: relative;
}

.SideBar li span {
    display: inline-block;
    color: #ff3300;
    left: 0;
    top: 0;
    position: absolute;
    font-weight: bold;
}

.SideBar li a {
    color: white;
    text-decoration: none;
    padding: 0 0 5px 15px;
    display: inline-block;
}

.SideBar .TopList-Community-State svg:first-child {
    margin-left: 5px;
}

.SideBar .TopList-Community-State svg {
    color: #ff3300;
    margin-left: 10px;
}

.SideBar .TopList-Community-State {
    float: right;
    font-size: 85%;
    margin-top: 5px;
    text-align: right;
    background: #191919;
    padding: 0;
    position: absolute;
    right: 0;
    bottom: -6px;
}

.Community-Pick-Community-State svg:first-child {
    margin-left: 5px;
}

.Community-Pick-Community-State svg {
    color: #FF3300;
    margin-left: 10px;
}

.Community-Pick-Community-State {
    background: #191919;
    bottom: -6px;
    float: right;
    font-size: 85%;
    margin-top: 5px;
    padding: 0;
    position: absolute;
    right: 0;
    text-align: right;
}

.Content-Column .Calendar-Box .Label, .SideBar .Label {
    position: relative;
    background: #f30;
    display: inline-block;
    font-weight: 700;
    padding: 5px 10px;
    border-radius: 4px;
    font-family: 'Montserrat',sans-serif;
    margin: 0;
    text-transform: uppercase;
    font-size: 10px;
}

.Community-Pick-QuoteResourceTitle a {
    color: #ff3300;
}

.Community-Pick-QuoteResourceTitle {
    display: block;
    padding-bottom: 10px;
}


.Community-Pick-QuoteAuthor {
    display: block;
    text-align: right;
    margin-bottom: 10px;
}

.Community-Pick-Quote {
    color: white;
    text-align: left;
    display: block;
    margin-bottom: 10px;
}

.SideBar .Community-Top-List-Title, .Community-Pick-Title, .SideBar .Calendar-Box .Calendar-Title, .SideBar .Community-Tags-Title {
    color: var(--pelcom-red);
    margin-top: 0;
    padding-bottom: 10px;
}

.SideBar .Community-Tags-Title {
    padding-bottom: 0;
    margin-bottom: 10px;
}

.Community-Pick-Title, .SideBar .Calendar-Box .Calendar-Title {
    padding-left: 15px;
}

.SideBar .Community-Top-List-Title {
    border-bottom: 1px solid #565656;
}

.Content-Column .Calendar-Box .Calendar-Title {
    display: block;
    position: relative;
    left: -2rem;
    top: -2rem;
    font-size: 2rem;
    text-shadow: 5px 5px 5px black;
    transform: translate3d(0px, 0px, 0px);
    -webkit-font-smoothing: antialiased;
    margin-bottom: -1.5rem;
    z-index: 110;
}

.SideBar .Calendar-Box .Calendar-Title {
    cursor: pointer;
    border-bottom: 1px solid #565656;
}

.Community-Pick-Box, .SideBar .Community-Tags-Box {
    position: relative;
    padding-top: 10px;
    border-top: 1px solid #ff3300;
    border-bottom: 1px solid #ff3300;
}

.Community-Tags-Box a {
    display: inline-block;
    margin: 8px 15px 8px 0;
    font-size: 15px;
}

.Community-Pick-Box .svg-inline--fa {
    margin-right: 0.3em;
}

.Community-Pick-Quote-Wrapper {
    margin: 0 15px 20px 15px;
    border-top: 1px solid #565656;
    border-bottom: 1px solid #565656;
    position: relative;
}

.SideBar .Calendar-Box img.Match-Team-Logo {
    display: none;
}

.SideBar .Calendar-Box .Tournament-Info {
    display: none;
 }

.SideBar .Calendar-Box {
    background: rgba(41, 44, 49, 0.4);
    max-width: 320px;
    padding: 10px;
    margin-top: 2rem;
    color: white;
}

section.SideBar div > div {
    padding: 0;
}

section.SideBar .Reservation-Calendar-Container > div {
    padding: 10px;
}

section.SideBar div figure.image {
    margin: 10px 0;
    padding: 0;
}

section.SideBar div figure.image img {
    max-width: 256px;
    height: auto;
    display: block;
    margin: 0 auto;
}

.SideBar div.Ad-Box {
    padding: 0;
    margin: 10px auto;
    text-align: center;
}

@media (max-width: 1023px) {
    .Content {
        flex-direction: column; /* Stacks sidebar and main content vertically */
    }

    .SideBar {
        flex: unset;
        width: 100%;
        margin: 0 auto 15px auto;
        max-width: 90%;
        background: transparent;
    }

    .SideBar .Calendar-Box {
        margin: 0 auto;
    }
}

@media (max-width: 767px) {
    .Community-Pick-Box[data-position="sidebar"] {
        display: none;
    }
}
