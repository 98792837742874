.Content-Column {
    width: 100%;
    max-width: 1200px;
}

.Content-Column .Label {
    position: relative;
    background: #f30;
    display: inline-block;
    font-weight: 700;
    padding: 5px 10px;
    border-radius: 4px;
    font-family: 'Montserrat',sans-serif;
    margin: 0;
    text-transform: uppercase;
    font-size: 10px;
}

.Content-Fold.col-md-10 {
    width: 100%;
}

.col-md-2 + .Content-Fold.col-md-10 {
    width: 83.3333%;
}

.Common-Page .Content-Column h1, .Common-Page .Content-Column h2 {
    max-width: 900px;
    margin-left: auto;
    margin-right: auto;
    overflow-wrap: break-word;
}

.Common-Page .Content-Column .Community-Box h2 {
    max-width: 100%;
    margin-left: 0;
    margin-right: 0;
}

.Common-Page article figure.image, .Common-Page article figure.media {
    margin: 0 auto;
}
.Common-Page article figure.wp-caption {
    max-width: 100%;
    margin: 0 auto;
}

@media (max-width: 1023px) {
    .Content-Column {
        width: 90%;
        margin: 0 5%;
    }

    .col-md-2 + .Content-Fold.col-md-10 {
        width: 100%;
    }
}
