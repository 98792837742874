
.Ad-Box {
    padding: 15px 0;
}

.SideBar div.Ad-Box {
    height: auto;
    min-height: 250px;
    position: relative;
    margin-left: -7px;
}

.SideBar div.Ad-Box::before {
    content: "(mainos)";
    width: 100%;
    top: calc(50% - 10px);
    left: 0;
    text-align: center;
    position: absolute;
}

.Ad-Box img {
    max-width: 100%;
}

@media (max-width: 767px) {
    .Ad-Box figure.image {
        padding: 0;
        margin: 0;
    }

    .Ad-Box img {
        height: auto;
    }

    .SideBar div.Ad-Box {
        margin-left: 0;
    }
}
